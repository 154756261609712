/*
  This is required to hide the recaptcha badge
  https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
*/
.grecaptcha-badge {
  visibility: hidden;
}

/* This is required to style the Adyen fields. */
.js-iframe {
  border: none;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
